import jk from "./jk.png";
import crong from "./crong.png";
import honux from "./honux.png";
import ivy from "./ivy.png";
import silver from "./silver.png";
import masterjs from "./masterjs.png";
import place1 from "./place1.png";
import place2 from "./place2.png";
import place3 from "./place3.png";
import place4 from "./place4.png";
import place5 from "./place5.png";
import place6 from "./place6.png";
import place7 from "./place7.png";
import howToStudy from "./howtostudy.png";

export default {
  jk,
  crong,
  honux,
  ivy,
  silver,
  masterjs,
  place1,
  place2,
  place3,
  place4,
  place5,
  place6,
  place7,
  howToStudy,
};
